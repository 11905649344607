import React, { useState } from 'react';
import { TABLE as TranslateKeys } from '@paquery-team/lib-paquery-language/keys';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Col, DatePicker, Tooltip } from 'antd';
import { createUseStyles } from 'react-jss';
import { RangePickerProps } from '../../types';

const { RangePicker } = DatePicker;

const ApiDateFilterFormat = 'YYYY-MM-DD';

const paqueryGreenBorder = '#0ec9c3';

const useStyles = createUseStyles({
  '@keyframes heartBeat': {
    '0%': {
      boxShadow: `0px 0px 4px ${paqueryGreenBorder}`,
    },
    '50%': {
      boxShadow: `0px 0px 10px ${paqueryGreenBorder}`,
    },
    '100%': {
      boxShadow: `0px 0px 4px ${paqueryGreenBorder}`,
    },
  },
  inputRequired: {
    outline: 'none',
    boxShadow: `0px 0px 4px ${paqueryGreenBorder}`,
    animationName: '$heartBeat',
    animationDuration: '1s',
    animationIterationCount: 'infinite',
  },
});

const RangePickerOPL = ({ onDateSelection, required }: RangePickerProps) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [calendarData, setCalendarData] = useState<any>([]);
  const visible = required?.value && calendarData && calendarData.length == 0;

  const today = t(TranslateKeys.DATEFILTER.TODAY);
  const week = t(TranslateKeys.DATEFILTER.ONEWEEK);
  const month = t(TranslateKeys.DATEFILTER.ONEMONTH);
  const days45 = `45 ${t(TranslateKeys.DATEFILTER.DAYS)}`;
  const ranges : any = {};
  ranges[today] = [moment(), moment()];
  ranges[week] = [moment().subtract(1, "week"), moment()];
  ranges[month] = [moment().subtract(1, "month"), moment()];
  ranges[days45] = [moment().subtract(45, "day"), moment()];

  function handleDateSelection(dates : any){
    if (dates && dates.length > 0) {
      setCalendarData(dates)
      console.log({from: dates[0].format(ApiDateFilterFormat).toString(), to: dates[1].format(ApiDateFilterFormat).toString()})
      onDateSelection({from: dates[0].format(ApiDateFilterFormat).toString(), to: dates[1].format(ApiDateFilterFormat).toString()})
    } else {
      setCalendarData([])
    }
  }

  return (
    <Col xs={24} sm={12} lg={8}>
      <Tooltip
        destroyTooltipOnHide
        visible={visible}
        title={required?.message}
        getPopupContainer={(triggerNode) => triggerNode}
        placement="bottom"
      >
        <RangePicker
          data-testid='range-picker'
          allowClear={false}
          defaultPickerValue={[moment().subtract(45, "day"), moment().subtract(45, "day")]}
          className={visible ? classes.inputRequired : undefined}
          style={{ width: '100%' }}
          disabledDate={(current) =>
            current <= moment().subtract(45, "day") || current > moment()
          }
          onChange={handleDateSelection}
          ranges={ranges}
        />
      </Tooltip>
    </Col>
  );
};

RangePickerOPL.defaultProps = {
  required: {
    value: false,
  },
};

export default RangePickerOPL;
