import React from 'react';
import { Row, Col } from 'antd';
import { TableExpandedDataProps } from '../types';

const TableExpandedData = ({ data, columns }: TableExpandedDataProps) => (
  <Row>
    <Col style={{ paddingLeft: '14px' }}>
      <ul>
        {columns.map((column) => (
          <li>
            <span style={{ fontWeight: 'bold' }}>{`${column.title}: `}</span>
            <span>{data[column.dataIndex]}</span>
          </li>
        ))}
      </ul>
    </Col>
  </Row>
);

export default TableExpandedData;
