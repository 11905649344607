import { createUseStyles } from 'react-jss';

const paqueryGreen = '#0db8b2';
const paqueryBackgroundColor = '#254370';
const paqueryFontColor = '#fff';
const paqueryGreenBorder = '#0ec9c3';

export default createUseStyles({
  header: {
    background: paqueryBackgroundColor,
    color: paqueryFontColor,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 35px',
  },
  logoutButton: {
    margin: '2px 0 2px 1rem',
    background: paqueryGreen,
    '& span': {
      margin: '0 !important',
    },
    '&:hover span svg': {
      color: `${paqueryGreen} !important`,
    },
  },
  headerDecorator: {
    minHeight: 25,
    paddingTop: 1,
    background: paqueryGreen,
    '& span': {
      marginLeft: 35,
      color: paqueryFontColor,
      fontWeight: 'bold',
    },
  },
  buttonBack: {
    color: paqueryGreen,
  },
  buttonSupport: {
    marginRight: 20,
    lineHeight: '35px !important',
    borderRadius: 40,
    height: 40,
    width: 95,
    fontWeight: 'bold',
    '&:hover': {
      background: paqueryGreen,
      color: paqueryFontColor,
    },
  },
  headerButtonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: 160,
  },
  versionLangContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonLang: (props: ButtonLangProps) => ({
    margin: '0 5px',
    backgroundColor: 'transparent',
    fontSize: '100%',
    fontFamily: 'inherit',
    border: `1px solid ${paqueryGreenBorder}`,
    borderRadius: '50%',
    padding: 0,
    background: `url(${props?.icon})`,
    backgroundPosition: 'center center',
    backgroundSize: '150%',
    width: 32,
    height: 32,
    transition: '400ms all linear',
    '&:hover': {
      boxShadow: `0px 0px 4px 2px ${paqueryGreen}`,
    },
  }),
  buttonSelected: {
    boxShadow: `0px 0px 2px 1px ${paqueryGreen}`,
  },
});

interface ButtonLangProps {
  theme: Jss.Theme;
  icon: any;
};
