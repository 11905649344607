import { useState } from 'react';

export default function useGetRowColors(style) {
  const [classes] = useState(style);

  return function getRowColor(record) {
    let rowClass;
    switch (record.statusDescription) {
      case 'Arribado a PaQuery Point':
        rowClass = classes.deliverablePackage;
        break;
      case 'En Poder del Paquer':
        rowClass = classes.onPaquerHands;
        break;
      case 'Intento de Entrega 1':
        rowClass = classes.firstDeliveryTry;
        break;
      case 'Intento de Entrega 2':
        rowClass = classes.secondDeliveryTry;
        break;
      case 'Ingresado a PaQuery':
        rowClass = classes.atPaquery;
        break;
      default:
        break;
    }
    return rowClass;
  };
}