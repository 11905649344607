import React, {useEffect, useState} from "react";
import { Avatar, Button, Card, Col, Modal, Row, Timeline } from "antd";
import { useTranslation } from 'react-i18next';
import {
  PACKAGEVIEW as TranslateKeys,
  DATE as DateTranslateKeys,
} from '@paquery-team/lib-paquery-language/keys';
import moment from "moment";

import { usePackageViewContext } from '../../context';
import { AttemptStatuses } from '../../defaultValues';
import { LogStatusPackage, Visit } from "../../types";
import { filterLogsByNewStatus } from "./utils";
import { CameraOutlined, CheckCircleOutlined } from "@ant-design/icons";

interface AttemptModalProps {
  status: number | undefined;
  visit: Visit | null;
}

const AttemptModal = ({ status, visit }: AttemptModalProps) => {
  const [t] = useTranslation();
  const [visible, setVisible] = useState(false);
  if (!AttemptStatuses.some((atStatus) => atStatus.id === status)) {
    return null;
  }
  if (!visit) {
    return null;
  }
  const visitImage = (
    <Avatar
      size='large'
      icon={<CameraOutlined style={{ margin: 125, fontSize: 100 }} />}
      shape='square'
      style={{ width: '100%', height: '100%' }}
      src={visit?.photo}
    />
  );
  return (
    <>
      <Modal
        title={null}
        closable={false}
        bodyStyle={{ margin: 0, padding: 0 }}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        centered
      >
        <Row>
          <Col span={24}>
            <p>
              <strong>{t(TranslateKeys.VIEW.STATUSCARD.DESCRIPTION)}: </strong>
              {visit?.reason || t(TranslateKeys.VIEW.STATUSCARD.NOVISITINFO)}
            </p>
          </Col>
          <Col span={24}>
            {visitImage}
          </Col>
        </Row>
      </Modal>
      <div>
        <Button
          type='link'
          htmlType='button'
          onClick={() => setVisible(true)}
        >
          {t(TranslateKeys.VIEW.STATUSCARD.SHOWREASON)}
        </Button>
      </div>
    </>
  );
};

const PacketTimeline = () => {
  const [t] = useTranslation();
  const { packet, packageStatuses } = usePackageViewContext();

  if (!packet) return null;

  const [logStatusPackagesSorted, setLogStatusPackagesSorted] = useState<
    LogStatusPackage[] | null
  >(null);

  const [lastVisit, setLastVisit] = useState<Visit | null>(null);
  const [currentStatus, setCurrentStatus] = useState<LogStatusPackage | null>(
    null,
  );

  // Sorts the logStatusPackages by date and add the first status (When it was created)
  useEffect(() => {
    if (packet) {
      if (packet.logStatusPackages) {
        const statusSorted = filterLogsByNewStatus(packet.logStatusPackages);
        statusSorted.unshift({
          creationDate: packet.creationDate,
          message: '',
          nextStatus: 1,
          previusStatus: 1,
        });
        setLogStatusPackagesSorted(statusSorted);
      }
    }
  }, [packet, t]);

  // Sets current logStatusPackage (the last one)
  useEffect(() => {
    if (logStatusPackagesSorted) {
      const [lastStatus] = logStatusPackagesSorted.slice(-1);
      if (lastStatus) {
        setCurrentStatus({
          creationDate: moment(lastStatus.creationDate).format(
            t(DateTranslateKeys.TABLEDATETIMEFORMAT),
          ),
          nextStatus: packet.status as number,
          message: '',
          previusStatus: lastStatus.previusStatus,
        });
      }
    }
  }, [packet, logStatusPackagesSorted, t]);

  // If packet is in a status that has a visit, sets the last visit
  useEffect(() => {
    if (packet) {
      const attemptStatus = AttemptStatuses.find(
        (atStatus) => atStatus.id === packet.status,
      );
      if (attemptStatus) {
        const visit = packet.shippingScheduleDestination.visits.find(
          (v: Visit) => v.numberVisit === attemptStatus.numberVisit,
        );
        setLastVisit(visit);
      }
    }
  }, [packet, t]);

  let previousLogStatusPackages: LogStatusPackage[] = [];
  if (logStatusPackagesSorted) {
    // Remove the last status, because it is the current status
    previousLogStatusPackages = logStatusPackagesSorted.slice(0, -1);
  }

  let packetStatus = {
    value: -1,
    name: '',
  };

  if (packageStatuses && currentStatus) {
    const { nextStatus } = currentStatus;
    const packageStatus = packageStatuses.find(
      (statusItem) => nextStatus === statusItem.value,
    );
    if (packageStatus) {
      packetStatus = packageStatus;
    }
  }

  return (
    <Card title="Arbol de cambios" bodyStyle={{ width: '100%' }}>
      <Timeline mode="left">
        {previousLogStatusPackages.map((log) => {
          const { packageStatuses } = usePackageViewContext();

          let packetStatus = {
            value: -1,
            name: '',
          };
        
          if (packageStatuses) {
            const packageStatus = packageStatuses.find(
              (statusItem) => log.nextStatus === statusItem.value,
            );
            if (packageStatus) {
              packetStatus = packageStatus;
            }
          }
        
          return (
            <Timeline.Item 
              label={
                moment(log.creationDate).format(t(DateTranslateKeys.TABLEDATETIMEFORMAT))
              }
            >
              {packetStatus.name}
            </Timeline.Item>
          );
        })}
        {currentStatus && 
          <Timeline.Item
            label={currentStatus.creationDate}
            dot={<CheckCircleOutlined />}
            color="green"
          >
            {packetStatus.name}
          </Timeline.Item>
        }
      </Timeline>
      <Row justify="center" align="top">
        <Col>
          <AttemptModal status={currentStatus?.nextStatus} visit={lastVisit} />
        </Col>
      </Row>
    </Card>
  );
};

export default PacketTimeline;
