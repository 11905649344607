import { LogStatusPackage } from '../../types';

/**
 * Returns positive number if log1 is newer than log2,
 */
export const compareLogByDate = (
  log1: LogStatusPackage,
  log2: LogStatusPackage,
) => {
  return (
    new Date(log1.creationDate).valueOf() -
    new Date(log2.creationDate).valueOf()
  );
};

export const filterLogsByNewStatus = (
  logs: LogStatusPackage[],
): LogStatusPackage[] => {
  // if there is no logs, return empty array
  if (!logs.length) {
    return [];
  }

  // if there is only one status in the logs, return it
  if (logs.length === 1) {
    return logs;
  }

  // if there are more than one status in the logs,
  // sorts the logs by date-ascendent order and filters logs by new status
  /**
   * Logs sorted by date-ascendent order
   */
  const sortedLogs = logs.slice().sort(compareLogByDate);

  /**
   * Logs sorted by date-ascendent order and filtered by new status,
   * the nextStatus property cannot be repeated
   */
  const filteredLogs: LogStatusPackage[] = [];

  sortedLogs.forEach((log) => {
    const index = filteredLogs.findIndex(
      (l) => l.nextStatus === log.nextStatus,
    );
    // If the nextStatus is not repeated, push it to the filteredLogs
    if (index === -1) {
      filteredLogs.push(log);
      return;
    }
    // If the nextStatus is repeated, push the last log of the filteredLogs
    if (compareLogByDate(log, filteredLogs[index]) > 0) {
      filteredLogs[index] = log;
    }
  });
  return filteredLogs;
};

export default {
  compareLogByDate,
  filterLogsByNewStatus,
};
