import { createUseStyles } from 'react-jss';

export default createUseStyles({
  deliverablePackage: {
    background: '#B1DF7C', // Yellow Green
  },
  onPaquerHands: {
    background: '#E0FF70', // Arctic Lime
  },
  firstDeliveryTry: {
    background: '#FFBE5C', // Yellow Orange
  },
  secondDeliveryTry: {
    background: '#F0876A', // Flame
  },
  atPaquery: {
    background: '#79F6F2', // Tiffany Blue
  },
});
