import React from 'react';
import { Card, Col, Result, Row, Spin } from "antd";

import { PackageViewProps } from './types';
import { PackageViewProvider, usePackageViewContext } from './context';
import { ActionButton, PaquerModal, Detail } from './components';
import PacketTimeline from './components/PacketTimeline';

const PackageViewContainer = () => {
  const { errorMessage, packet, loading } = usePackageViewContext();

  if (!loading && errorMessage)
    return <Result status='warning' title={errorMessage} />;

  if (!packet) return null;

  return (
    <>
      <Card title={packet.externalCode} style={{ width: "100%" }} extra={<ActionButton />}>
        <Row gutter={[0, 32]}>
          <Col xs={24} lg={11}>
            <Detail />
          </Col>
          <Col xs={24} lg={12}>
            <PacketTimeline />
          </Col>
        </Row>
      </Card>
      <PaquerModal />
    </>
  );
};

export const PackageViewComponent = ({
  onReceiptDownload,
  packet,
  loading,
  errorMessage,
  paquer,
  packageSizes,
  packageStatuses,
  deliveryTerms,
  countries,
}: PackageViewProps) => {
  return (
    <PackageViewProvider
      onReceiptDownload={onReceiptDownload}
      packet={packet}
      paquer={paquer}
      loading={loading}
      errorMessage={errorMessage}
      packageSizes={packageSizes}
      packageStatuses={packageStatuses}
      deliveryTerms={deliveryTerms}
      countries={countries}
    >
      <Spin spinning={loading}>
        <PackageViewContainer />
      </Spin>
    </PackageViewProvider>
  );
};

PackageViewComponent.defaultProps = {
  onReceiptDownload: () => {
    // eslint-disable-next-line no-console
    console.warn('lib-package-view: onReceiptDownload not defined');
  },
  packageSizes: [],
  packageStatuses: [],
  deliveryTerms: [],
  countries: [],
  paquer: {
    name: '',
    avatar: '',
    lastName: '',
    code: '',
    mobile: '',
    rating: 0,
    city: {
      countryID: null,
    },
  },
  packet: {
    externalCode: '',
    status: null,
    creationDate: '',
    shippingScheduleDestination: {
      visits: [],
      driver: {
        name: '',
        phone: '',
      },
    },
    logStatusPackages: [],
  },
};

export default PackageViewComponent;