import React, { createContext, useContext, useState } from 'react';
import { PackageViewProps } from '../types';

interface IPackageViewContext extends PackageViewProps {
  setShowPaquerModal:
    | React.Dispatch<React.SetStateAction<boolean>>
    | (() => void);
  showPaquerModal: boolean;
}

export const PackageViewContext = createContext<IPackageViewContext>(null!);

export const usePackageViewContext = (): IPackageViewContext =>
  useContext<IPackageViewContext>(PackageViewContext);

interface PackageViewProviderProps extends PackageViewProps {
  children: JSX.Element;
}

export const PackageViewProvider = ({
  onReceiptDownload,
  packet,
  loading,
  errorMessage,
  paquer,
  packageSizes,
  packageStatuses,
  deliveryTerms,
  countries,
  children,
}: PackageViewProviderProps) => {
  const [showPaquerModal, setShowPaquerModal] = useState(false);

  return (
    <PackageViewContext.Provider
      value={{
        onReceiptDownload,
        packet,
        loading,
        errorMessage,
        paquer,
        packageSizes,
        packageStatuses,
        deliveryTerms,
        setShowPaquerModal,
        showPaquerModal,
        countries,
      }}
    >
      {children}
    </PackageViewContext.Provider>
  );
};
