import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { MenuProps } from '../types';

const { SubMenu } = Menu;

function NavigationMenu({ menuItems }: MenuProps) {
  return (
    <Menu theme="light" mode="inline" forceSubMenuRender>
      {menuItems.map((menuItem) =>
        !menuItem.disabled && menuItem.subMenuItems && menuItem.subMenuItems.length > 0 ? (
          <SubMenu
            key={menuItem.key}
            title={
              <span>
                {menuItem.icon}
                <span>{menuItem.title}</span>
              </span>
            }
            disabled={menuItem.disabled}
          >
            {menuItem.subMenuItems.map((submenuItem) => (
              <Menu.Item key={submenuItem.key} disabled={submenuItem.disabled}>
                <Link to={submenuItem.link}>
                  {submenuItem.icon}
                  <span>{submenuItem.title}</span>
                </Link>
              </Menu.Item>
            ))}
          </SubMenu>
        ) : (
          <Menu.Item key={menuItem.key} disabled={menuItem.disabled}>
            <Link to={menuItem.link}>
              {menuItem.icon}
              <span>{menuItem.title}</span>
            </Link>
          </Menu.Item>
        ),
      )}
    </Menu>
  );
}

NavigationMenu.defaultProps = {
  menuItems: [],
};

export default NavigationMenu;
