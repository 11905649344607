import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/es';
import {
  PACKAGEVIEW as TranslateKeys,
  DATE as DateTranslateKeys,
} from '@paquery-team/lib-paquery-language/keys';
import { usePackageViewContext } from '../context';
import { Col, Row, Statistic } from 'antd';

export const Detail = () => {
  const [t] = useTranslation();
  const { i18n } = useTranslation();

  const { packet, packageSizes, packageStatuses, deliveryTerms } =
    usePackageViewContext();

  if (!packet) return null;

  const size = packageSizes?.find(
    (sizeItem) => packet.packageSize === sizeItem.value,
  ) ?? { name: `${packet.packageSize}` };
  const status = packageStatuses?.find(
    (statusItem) => packet.status === statusItem.value,
  ) ?? { name: `${packet.status}` };
  const deliveryTerm = deliveryTerms?.find(
    (dtl) => packet.deliveryTerm === dtl.value,
  ) ?? { name: `${packet.deliveryTerm}` };

  const addressDetail =
    packet.shippingScheduleDestination?.shippingAddress?.addressDetail ?? '-';

  const concatCaptionAndDetail = (): string => {
    if (packet.caption && packet.detail)
      return `${packet.caption} - ${packet.detail}`;
    if (!packet.caption) return packet.detail;
    if (!packet.detail) return packet.caption;
    return '';
  };
  const MAX_SHOW_TEXT = 34;
  let detail = '';
  const captionAndDetail = concatCaptionAndDetail();
  if (captionAndDetail) {
    if (captionAndDetail.length > MAX_SHOW_TEXT) {
      detail = `${captionAndDetail.substring(0, MAX_SHOW_TEXT)}...`
    } else {
      detail = `${captionAndDetail}`
    }
  }

  return (
    <div style={{ backgroundColor: "white" }}>
      <Row gutter={[16, 20]}>
        {detail && <Col xs={24}>
          <Statistic
            valueStyle={{ fontSize: "1.25rem" }} 
            title={t(TranslateKeys.DATA.TITLE)} 
            value={detail} />
        </Col>}
        <Col>
          <Statistic
            valueStyle={{ fontSize: "1.25rem" }} 
            title={t(TranslateKeys.VIEW.CARD.SERVICE)} 
            value={deliveryTerm.name} />
        </Col>
        <Col>
          <Statistic
            valueStyle={{ fontSize: "1.25rem" }} 
            title={t(TranslateKeys.VIEW.CARD.STATUS)} 
            value={status.name} />
        </Col>
        <Col>
          <Statistic
            valueStyle={{ fontSize: "1.25rem" }} 
            title="tamaño" 
            value={size.name} />
        </Col>
        {packet.shippingScheduleDestination && 
          packet.shippingScheduleDestination.scheduledDate  && 
          <Col>
            <Statistic
              valueStyle={{ fontSize: "1.25rem" }}
              title={t(TranslateKeys.VIEW.CARD.DELIVERYDEADLINE)}
              value={
                moment(packet.shippingScheduleDestination.scheduledDate)
                .locale(i18n.language)
                .format(t(DateTranslateKeys.PACKAGEVIEWSTATUSFORMAT))
              }
            />
          </Col>
        }
        <Col>
          <Statistic
            valueStyle={{ fontSize: "1.25rem" }}
            title={t(TranslateKeys.VIEW.CARD.DESTINY)}
            value={addressDetail}
            />
        </Col>
      </Row>
    </div>
  );
};

export default Detail;
