import React from 'react';
import { Pagination, Row, Col } from 'antd';
import { FooterProps } from '../types';

const Footer = ({
  totalElements,
  pageNumber,
  pageSize,
  onChange,
}: FooterProps) => (
  <Row justify="end">
    <Col>
      <Pagination
        showSizeChanger
        onChange={(number, size) =>
          onChange({
            pageNumber: number - 1,
            pageSize: size,
          })
        }
        onShowSizeChange={(_, size) =>
          onChange({
            pageNumber,
            pageSize: size,
          })
        }
        pageSize={pageSize}
        current={pageNumber + 1}
        total={totalElements}
        pageSizeOptions={['10', '20', '30', '50']}
      />
    </Col>
  </Row>
);

Footer.defaultProps = {
  totalElements: 0,
};

export default Footer;
