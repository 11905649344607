import React, { useState, useRef, useEffect} from 'react';
import { TABLE as TranslateKeys } from '@paquery-team/lib-paquery-language/keys';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { Row, Col, Input, Select, Button, Tooltip } from 'antd';
import {
  DownCircleOutlined,
  RedoOutlined,
  QrcodeOutlined,
} from '@ant-design/icons';
import RangePickerOPL from './RangePickerOPL';
import {
  SearcherProps,
  SelectorProps,
  TableHeaderProps,
} from '../../types';

const WAIT_INTERVAL = 600;
const { Option } = Select;
const { Search } = Input;

const paqueryGreenBorder = '#0ec9c3';

const useStyles = createUseStyles({
  addButton: {
    color: '#fff',
    background: '#0db8b2',
    border: '0',
    padding: '4px 6px',
    cursor: 'pointer',
    transition: '0.3s',
    '&:hover': {
      background: '#60ebe4',
      color: '#fff',
    },
    '&:disabled': {
      background: '#cccccc',
      cursor: 'default',
    },
  },
  '@keyframes heartBeat': {
    '0%': {
      boxShadow: `0px 0px 4px ${paqueryGreenBorder}`,
    },
    '50%': {
      boxShadow: `0px 0px 20px ${paqueryGreenBorder}`,
    },
    '100%': {
      boxShadow: `0px 0px 4px ${paqueryGreenBorder}`,
    },
  },
  inputRequired: {
    border: `1px solid ${paqueryGreenBorder}`,
    outline: 'none',
    boxShadow: `0px 0px 4px ${paqueryGreenBorder}`,
    animationName: '$heartBeat',
    animationDuration: '1s',
    animationIterationCount: 'infinite',
  },
});

const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [value, delay]);

  return debouncedValue;
};

const Selector = ({
  list,
  onChange,
  placeholder,
  defaultValue,
  required,
  showAll,
}: SelectorProps) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [visibleRequired, setVisibleRequired] = useState(false);

  useEffect(() => {
    if (required?.value && defaultValue === null) {
      setVisibleRequired(true);
    } else {
      setVisibleRequired(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [required!.value, defaultValue]);

  return (
    <Tooltip
      destroyTooltipOnHide
      visible={visibleRequired}
      title={required?.message}
      getPopupContainer={(triggerNode) => triggerNode}
    >
      <Select
        defaultValue={defaultValue}
        showSearch
        style={{ width: '100%' }}
        className={visibleRequired ? classes.inputRequired : undefined}
        placeholder={placeholder}
        optionFilterProp="children"
        onChange={onChange}
        filterOption={(input: string, option: any) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {showAll && (
          <Option value="">{t(TranslateKeys.HEADER.ALLOPTIONS)}</Option>
        )}
        {list.map((aStatus) => (
          <Option key={aStatus.value} value={aStatus.value}>
            {aStatus.name}
          </Option>
        ))}
      </Select>
    </Tooltip>
  );
};

Selector.defaultProps = {
  defaultValue: null,
  required: {
    value: false,
  },
  showAll: false,
};

const Searcher = ({ placeholder, onSearching, defaultValue, numeric, allowEmptySearch, ref }: SearcherProps) => {
  const [searchText, setSearchText] = useState('');
  const mounted = useRef() as React.MutableRefObject<boolean>;
  const debouncedSearchText = useDebounce(searchText, WAIT_INTERVAL);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (allowEmptySearch) return onSearching(debouncedSearchText.trim())
      if (debouncedSearchText.trim()) onSearching(debouncedSearchText);
    }
  }, [debouncedSearchText, mounted, onSearching]);
  
  const onChange = (e: any) => {
    const { value: inputValue } = e.target;
    if (numeric && !Number(inputValue) && inputValue !== '') return;
    setSearchText(inputValue);
  }

  return (
    <Search
      ref={ref}
      value={searchText}
      defaultValue={defaultValue || ''}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

Searcher.defaultProps = {
  placeholder: 'Ingrese valor a buscar',
  ref: null,
  numeric: false,
  allowEmptySearch: false,
};

export const TableHeader = ({
  dateItems,
  selectors,
  searchers,
  onExportCsv,
  onGenerateLabels,
  customButtons,
  refresh,
  addUrl
}: TableHeaderProps) => {
  const [t] = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const minimalButtonsPresent = onGenerateLabels || (onExportCsv && onExportCsv.callback);

  let refreshButton;
  if (refresh) {
    refreshButton = (
      <Col xs={4}>
        <button
          type="button"
          className={classes.addButton}
          onClick={refresh}
          style={{ marginRight: 4 }}
        >
          <RedoOutlined />
        </button>
        </Col>
    );
  }
  
  let addButton = null;
  if (addUrl) {
    addButton = (
      <Col xs={20} md={4}>
        <button
          type="button"
          className={classes.addButton}
          onClick={() => history.push(addUrl)}
        >
          {t(TranslateKeys.HEADER.ADDBUTTON)}
        </button> 
      </Col>
    );
  }

  let renderCustomButtons = null;
  if (customButtons) {
    renderCustomButtons = 
      customButtons.map((customButton) => 
        <Col xs={24} sm={12} md={8} lg={4}>
          {customButton}
        </Col>
      );
  }

  let renderSelectors;
  let renderSearchers;
  //const inputSpan = Math.floor( 24 / ((selectors ? selectors.length : 0) + (searchers ? searchers.length : 0)));
  if (selectors && selectors.length > 0) {
    renderSelectors = selectors.map((selector: SelectorProps) => (
      <Col xs={24} sm={12} md={6} lg={4}>
        <Selector {...selector} />
      </Col>
    ));
  }
  if (searchers && searchers.length > 0) {
    renderSearchers = searchers.map((search : SearcherProps) => (
      <Col xs={24} sm={12} md={6} lg={4}>
        <Searcher {...search} />
      </Col>
    ));
  } 

  return (
    <Row gutter={[8, 8]}>
      <Col xs={24} md={6} lg={4}>
        <Row justify='start'>
          {refreshButton}
          {addButton}
        </Row>
      </Col>
      <Col xs={24} md={minimalButtonsPresent ? 15 : 18} lg={minimalButtonsPresent ? 17 : 20}>
        <Row justify='end' gutter={[8, 8]}>
          {renderCustomButtons}
        </Row>
      </Col>
      {minimalButtonsPresent && <Col xs={24} md={3}>
        <Row justify='end' gutter={[4, 4]}>
          { onGenerateLabels && (
            <Col>
              <Tooltip title={t(TranslateKeys.HEADER.PRINTLABELS)}>
                <Button onClick={onGenerateLabels} icon={<QrcodeOutlined />} />
              </Tooltip>
            </Col>
          )}
          { (onExportCsv && onExportCsv.callback) && (
            <Col>
              <Tooltip title={t(TranslateKeys.HEADER.EXPORTREPORTS)}>
                <Button loading={onExportCsv.disabled} onClick={onExportCsv.callback} icon={<DownCircleOutlined />} />
              </Tooltip>
            </Col>
          )}
        </Row>
      </Col>}
      <Col span={24}> 
        <Row justify='end' gutter={[8, 8]}>  
          {renderSearchers}{renderSelectors}
        </Row>
      </Col>
      <Col span={24}>
        <Row justify='end' gutter={8}> 
          {dateItems && (
            <RangePickerOPL
              {...dateItems}
            />
          )}
        </Row>
      </Col>
    </Row>
  );
};

TableHeader.defaultProps = {
  selectors: null,
  searchers: null,
  addUrl: null,
  additionalButton: null,
  dateItems: null,
  onExportCsv: {
    callback: null,
    disabled: false,
  },
  onGenerateLabels: null,
  refresh: null,
  customButtons: null,
};

export default TableHeader;
