import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './LangSelector.styles';

function LangButton({ id, selected, text, alt }: LangButtonProps) {
  const classes = useStyles();
  const { i18n } = useTranslation();
  return (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <button
      type="button"
      className={`${classes.buttonLang} ${selected && classes.buttonSelected}`}
      onClick={() => i18n.changeLanguage(id)}
      title={alt}
    >
      {text}
    </button>
  );
}

interface LangButtonProps {
  id: string;
  selected: boolean;
  text: string;
  alt?: string;
};

function LangSelector({ containerStyle }: LangSelectorProps) {
  const {
    i18n: { language },
  } = useTranslation();
  const languages : LangButtonProps[] = [
    {
      id: 'es',
      selected: language === 'es',
      text: 'Español',
      alt: 'Seleccionar idioma Español'
    },
    {
      id: 'en',
      selected: language === 'en',
      text: 'Inglés',
      alt: 'Seleccionar idioma Inglés'
    },
    {
      id: 'pr',
      selected: language === 'pr',
      text: 'Portugués',
      alt: 'Seleccionar idioma Portugués'
    },
  ];
  return (
    <div style={containerStyle}>
      {languages.map((lang) => (
        <LangButton
          key={lang.id}
          id={lang.id}
          selected={lang.selected}
          text={lang.text}
          alt={lang.alt}
        />
      ))}
    </div>
  );
}

interface LangSelectorProps {
  containerStyle?: any;
};

LangSelector.defaultProps = {
  containerStyle: {},
};

export default LangSelector;
