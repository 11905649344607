import { createUseStyles } from 'react-jss';

const paqueryGreen = '#0db8b2';
// const paqueryGreenBorder = '#0ec9c3';

export default createUseStyles({
  versionLangContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonLang: {
    margin: '0 10px',
    backgroundColor: 'transparent',
    fontSize: '100%',
    fontFamily: 'inherit',
    color: `${paqueryGreen}`,
    border: 'none',
    outline: 'none',
    borderBottom: `1px solid ${paqueryGreen}`,
    padding: 0,
    height: 22,
    transition: '200ms all linear',
    '&:hover': {
      cursor: 'pointer',
      borderBottom: `1.5px solid #64D5E6`,
      color: `#64D5E6`,
    },
  },
  buttonSelected: {
    borderBottom: `1.5px solid #64D5E6`,
    color: `#64D5E6`,
  },
});
