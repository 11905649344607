import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Col, Row, Avatar, Button, Divider } from 'antd';
import { UserOutlined, WhatsAppOutlined } from '@ant-design/icons';

import { PACKAGEVIEW as TranslateKeys } from '@paquery-team/lib-paquery-language/keys';
import { usePackageViewContext } from '../context';

const URLWhatsapp = 'https://web.whatsapp.com/send?phone=';

export const PaquerModal = () => {
  const [t] = useTranslation();
  const { paquer, showPaquerModal, setShowPaquerModal, countries } =
    usePackageViewContext();

  if (paquer === null || paquer === undefined) return null;

  let countryCode = '';
  if (countries) {
    const paquerCountry = countries.find(
      (country) => country.id === paquer.countryId,
    );
    if (paquerCountry) {
      countryCode = paquerCountry.dialingCode;
    }
  }
  const contactHref = `${URLWhatsapp}${countryCode}${paquer.mobile}`;

  const handleClose = () => {
    setShowPaquerModal(false);
  };

  return (
    <Modal
      visible={showPaquerModal}
      onCancel={handleClose}
      footer={null}
      closable={false}
      maskClosable
      centered
    >
      <Row justify='center' style={{ height: 176, margin: 20 }} gutter={14}>
        <Col xs={10}>
          <Avatar
            icon={<UserOutlined style={{ margin: '29%', fontSize: 70 }} />}
            shape='square'
            style={{ width: '100%', height: '100%' }}
            src={paquer.avatar}
          />
        </Col>
        <Col xs={14}>
          <Row justify='center'>
            <Col
              xs={24}
              style={{ fontSize: 14, fontWeight: 500, color: 'gray' }}
            >
              {t(TranslateKeys.VIEW.PAQUERMODAL.NAME)}
            </Col>
            <Col xs={24} style={{ fontSize: 18, fontWeight: 500 }}>
              {`${paquer.name}
              ${paquer.lastName}`}
            </Col>
          </Row>
          <Divider style={{ margin: 6 }} />
          <Row justify='center'>
            <Col
              xs={24}
              style={{ fontSize: 14, fontWeight: 500, color: 'gray' }}
            >
              Paquer ID
            </Col>
            <Col xs={24} style={{ fontSize: 18, fontWeight: 500 }}>
              {paquer.code}
            </Col>
          </Row>
          <Divider style={{ margin: 6 }} />
          <Row justify='center'>
            <Col
              xs={24}
              style={{ fontSize: 14, fontWeight: 500, color: 'gray' }}
            >
              {t(TranslateKeys.VIEW.PAQUERMODAL.RATING)}
            </Col>
            <Col xs={24} style={{ fontSize: 18, fontWeight: 500 }}>
              {paquer.rating}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify='center' style={{ marginTop: 44 }}>
        <Col xs={24} style={{ textAlign: 'center' }}>
          <Button
            data-testid='paquer-modal-contact-button'
            type='primary'
            ghost
            disabled={!paquer.mobile}
            href={contactHref}
            target='_blank'
            style={
              paquer.mobile
                ? { color: '#0db8b2', fontSize: 18, fontWeight: 500 }
                : { fontSize: 18, fontWeight: 500 }
            }
          >
            <WhatsAppOutlined />
            {`${t(TranslateKeys.VIEW.PAQUERMODAL.CONTACT)} ${paquer.name}
            ${paquer.lastName}`}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};
export default PaquerModal;
