export const statuses = {
  contactPaquerStatus: [3, 7, 31, 32],
  visitStatus: [31, 32],
  deliveredStatus: 20,
  aceptedByPaquerStatus: 7,
  ownedByPaquerStatus: 3,
};

export const TableDateTimeFormat = 'DD/MM/YY, HH:mm';

export const AttemptStatuses = [
  {
    id: 31,
    numberVisit: 1,
  },
  {
    id: 32,
    numberVisit: 2,
  },
];
