import { Button, notification } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PACKAGEVIEW as TranslateKeys } from '@paquery-team/lib-paquery-language/keys';

import { usePackageViewContext } from "../context";
import { statuses } from '../defaultValues';

const DownloadReceipt = () => {
  const [t] = useTranslation();
  const { onReceiptDownload } = usePackageViewContext();
  const [loadingReceiptDownload, setLoadingReceiptDownload] = useState(false);

  const handleOnReceiptDownload = async () => {
    setLoadingReceiptDownload(true);
    if (onReceiptDownload) {
      await onReceiptDownload();
    }
    setLoadingReceiptDownload(false);
  };

  return (
    <Button
      type='ghost'
      onClick={handleOnReceiptDownload}
      style={{ color: '#0db8b2', fontSize: 14 }}
      loading={loadingReceiptDownload}
    >
      {t(TranslateKeys.VIEW.RECEIPT.DOWNLOADBUTTON)}
    </Button>
  );
};

const ContactPaquerButton = () => {
  const [t] = useTranslation();
  const { paquer, setShowPaquerModal } = usePackageViewContext();

  const handlePaquerModal = () => {
    if ( paquer ) {
      setShowPaquerModal(true);
    } else {
      notification.error({
        message: t(TranslateKeys.NOTIFICATION.ERRORMESSAGE),
        description: t(TranslateKeys.NOTIFICATION.NOPAQUERDESCRIPTION),
      });
    }
  };
  return (
    <Button
      type='ghost'
      onClick={handlePaquerModal}
      style={{ color: '#0db8b2', fontSize: 14 }}
    >
      {t(TranslateKeys.VIEW.CONTACTPAQUERBUTTON)}
    </Button>
  );
};

export const ActionButton = () => {
  const { packet } = usePackageViewContext();

  if ( !packet ) return null;
  const { status } = packet;
  if ( statuses.contactPaquerStatus.includes(status) ) {
    return <ContactPaquerButton />;
  }
  if ( status === statuses.deliveredStatus ) {
    return <DownloadReceipt />;
  }
  return null;
};

export default ActionButton;