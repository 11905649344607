import React, { MouseEventHandler } from 'react';
import { Layout, Button, Popover } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  COMMON as TranslationKeys,
  MENUS as TranslationKeysMenus,
} from '@paquery-team/lib-paquery-language/keys';
import {
  RollbackOutlined,
  LogoutOutlined,
  CloseOutlined,
} from '@ant-design/icons';

import LangSelector from './components/LangSelector';
import NavigationMenu from './components/Menu';
import useStyles from './style';
import { NavigationLayoutProps } from './types';

const paqueryBgContentColor = '#fff';

function BackButton() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Button
      className={classes.buttonBack}
      shape="circle"
      icon={<RollbackOutlined />}
      onClick={() => history.goBack()}
    />
  );
}

function LogoffButton({logout}: LogoffButtonProps) {
  const classes = useStyles();
  return (
    <Button
      className={classes.buttonBack}
      shape="circle"
      icon={<LogoutOutlined />}
      onClick={() => logout()}
    />
  );
}

interface LogoffButtonProps {
  logout: Function,
};

const { Content, Header, Footer, Sider } = Layout;

export const NavigationLayout = ({
  userName,
  menuItems,
  logout,
  logo,
  showLangSelector,
  children,
} : NavigationLayoutProps & { children?: React.ReactNode }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <Layout style={{ minHeight: '100%' }}>
      <Header className={classes.header}>
        <img src={logo} alt="paquery-logo" style={{ 
          maxHeight: '35px', marginTop: '5px'
        }} />
        <div className={classes.headerButtonsContainer}>
          {menuItems && menuItems.length > 0 ? (
            <BackButton />
          ) : (
            <LogoffButton logout={logout} />
          )}
        </div>
      </Header>
      <div className={classes.headerDecorator}>
        <span style={{ textTransform: 'uppercase' }}>{userName}</span>
        <Popover content={t(TranslationKeysMenus.CLOSESESSION)}>
          <Button
            className={classes.logoutButton}
            onClick={logout as MouseEventHandler}
            size="small"
            icon={<CloseOutlined />}
          />
        </Popover>
      </div>
      <Layout style={{ minHeight: '100%' }}>
        {menuItems && menuItems.length > 0 && (
          <Sider theme="light" collapsible defaultCollapsed collapsedWidth="0">
            <NavigationMenu menuItems={menuItems} />
          </Sider>
        )}
        <Layout style={{ padding: '14px' }}>
          <Content style={{ background: paqueryBgContentColor, padding: 4 }}>
            {children}
          </Content>
          <Footer
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              padding: '0px 50px',
              height: '64px',
            }}
          >
            <span>{t(TranslationKeys.COPYRIGHT)}</span>
            <div className={classes.versionLangContainer}>
              {showLangSelector && (
                <LangSelector containerStyle={{ marginRight: 30 }} />
              )}
              <span>
                {t(TranslationKeys.VERSION, {
                  version: process.env.REACT_APP_VERSION,
                })}
              </span>
            </div>
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
};

NavigationLayout.defaultProps = {
  logo: '',
  logout: ()=>{},
  menuItems: [],
  profileNavigation: '',
  userName: '',
};

export default NavigationLayout;
